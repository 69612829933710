<template>
  <div>
    <div style="display: inline-block; margin-right: 10px">
      当前公司：{{ keyobj.Title }}
    </div>
    <el-radio-group v-model="keyword" @change="changeRadio">
      <el-radio-button
        v-for="item in radioList"
        :label="item.Id"
        :key="item.Id"
        :title="item.Title"
      >
        <span class="ellipsis">
          {{ item.Title }}
        </span>
      </el-radio-button>
    </el-radio-group>

    <el-dropdown
      trigger="click"
      v-if="CompanyList.length > 0"
      @command="handleCommand"
    >
      <el-radio-group v-model="keyword" @change="changeRadio">
        <el-radio-button label="more">
          <i class="el-icon-more"></i>
        </el-radio-button>
      </el-radio-group>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in CompanyList"
          :command="item.Id"
          :key="item.Id"
          >{{ item.Title }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    hidden: { require: false, type: Boolean, default: false },
  },
  data() {
    return {
      radioList: [],
      CompanyList: [],
      keyword: "",
      keyobj: {
        Title: "全部",
      },
    };
  },
  mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getComputedList(userInfo.Id);
  },
  methods: {
    // 展示列表点击
    changeRadio(e) {
      var obj = this.CompanyList.find((item) => {
        return item.Id == e;
      });
      var obj2 = this.radioList.find((item) => {
        return item.Id == e;
      });

      console.log(obj, obj2);
      if (obj) {
        this.keyobj = obj;
      }
      if (obj2) {
        this.keyobj = obj2;
      }

      this.$emit("radioChange", e);
    },
    // 更多列表点击
    handleCommand(e) {
      console.log(e);
      var obj = this.CompanyList.find((item) => {
        return item.Id == e;
      });
      var obj2 = this.radioList.find((item) => {
        return item.Id == e;
      });

      console.log(obj, obj2);
      if (obj) {
        this.keyobj = obj;
      } else {
        this.keyobj = obj2;
      }
      this.$emit("radioChange", e);
    },
    // 公司
    getComputedList(id) {
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          Search: {
            condition: "CreatorId",
            keyword: id,
          },
        })
        .then((res) => {
          if (res.Success) {
            this.CompanyList = res.Data.filter((item, index) => index >= 3);
            this.radioList = res.Data.filter((item, index) => index < 3);
            let str = res.Data.map((item) => item.Id).join(",");
            if (res.Data.length === 1) {
              this.keyword = str;
              this.$emit("complete", this.keyword);
              return;
            }
            if (!this.hidden) {
              this.radioList.unshift({
                Id: str,
                Title: "全部",
              });
            }
            this.keyobj = { Title: this.radioList[0].Title };
            this.keyword = this.radioList[0].Id;
            this.$emit("complete", this.keyword);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ellipsis {
  display: block;
  max-width: 70px;
  height: 10px;
  height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #d50700;
  border-color: #d50700;
  box-shadow: -1px 0 0 0 #d50700;
}
/deep/.el-radio-button__inner:hover {
  color: #d50700;
}
</style>
