<template>
  <el-dialog title="配置邮箱" :visible.sync="dialogVisible" width="30%">
    <el-form :model="entity" :rules="rules" ref="form" label-width="70px">
      <el-form-item label="邮箱" prop="Email">
        <el-input v-model="entity.Email"></el-input>
      </el-form-item>
      <el-form-item label="授权码" prop="EmailCode">
        <el-input v-model="entity.EmailCode"></el-input>
      </el-form-item>
      <el-form-item label="帮助">
        <el-image
          class="image"
          v-for="item in srcList"
          :key="item"
          :src="item"
          :preview-src-list="srcList"
        >
        </el-image>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleClose"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      Id: "",
      entity: {},
      srcList: [
        "https://hzq.yoojet.com/email1.jpg",
        "https://hzq.yoojet.com/email2.jpg",
        "https://hzq.yoojet.com/email3.jpg",
      ],
      rules: {
        Email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        EmailCode: [
          { required: true, message: "请输入授权码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    openDialog(Id) {
      this.loading = true;
      this.Id = Id;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      // 获取公司信息
      this.$http
        .post("/Base_Manage/Base_Company/GetTheData?id=" + Id, {})
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.entity = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    handleClose() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$http
          .post("/Base_Manage/Base_Company/SaveData", this.entity)
          .then((res) => {
            this.CompanyLoading = false;
            if (res.Success) {
              this.$message.success("操作成功");
              this.dialogVisible = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.image {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  margin-right: 8px;
}
</style>